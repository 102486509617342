import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import Currency from '@/models-ts/Currency'
import { Blockchain, BlockchainNames, EVM_BLOCKCHAIN, WalletGroup } from '@/constants/blockchain'
import Wallet from '@/models-ts/Wallet'
import { CURRENCIES, STABLECOINS } from '@/constants/currency'
import PrefferedCurrency from '@/models-ts/user/PrefferedCurrency'
import { getBlockchainIcon } from '@/utils-ts/wallet'

export default Vue.extend<any, any, any, any>({
  name: 'lx-currency-selection-modal',
  props: {
    onSuccess: Function,
    currencies: {
      type: Array as PropType<Array<PrefferedCurrency>>,
      default: []
    },
    forProfile: {
      type: Boolean,
      default: () => true
    },
  },
  data () {
    return {
      processing: true,
      saving: false,
      imgSrc: null,
      selectedBlockchain: null,
      selectedCurrencies: [],
      BlockchainNames,
    }
  },
  computed: {
    ...mapState('user', {
      wallets: (state: any) => state.wallets,
    }),
    hasTronWallets () {
      return (this.wallets || []).some((wallet: Wallet) => wallet.group === WalletGroup.TronLink)
    },
    blockchains () {
      return [...EVM_BLOCKCHAIN, Blockchain.Tron]
    },
    blockchainOptions () {
      return this.blockchains.map((b: Blockchain) => ({ key: b, name: BlockchainNames[b] }))
    },
    currencyOptions () {
      return CURRENCIES.filter(curr => curr.blockchain === this.selectedBlockchain)
    },
    selectedBlockchainOption () {
      return this.blockchainOptions.find((opt: any) => opt.key === this.selectedBlockchain)
    },
    allCheckboxValue () {
      switch (this.selectedCurrencies.length) {
        case 0: return false
        case CURRENCIES.length: return true
        default: return 'some'
      }
    },
    stableCheckboxValue () {
      const selectedStables = this.selectedCurrencies.filter((sc: Currency) => STABLECOINS.find(curr => this.compareCurrency(sc, curr)))
      switch (selectedStables.length) {
        case 0: return false
        case STABLECOINS.length: return true
        default: return 'some'
      }
    },
  },
  async created () {
    this.selectedBlockchain = Blockchain.Ethereum
    this.selectedCurrencies = [...this.currencies]
  },
  methods: {
    getBlockchainIcon,
    onSelectBlockchain (opt?: { key: Blockchain }) {
      if (opt) {
        this.selectedBlockchain = opt.key
      }
    },
    onBlockchainClick (blockchain: Blockchain) {
      this.selectedBlockchain = blockchain
    },
    isSelected (currency: Currency) {
      return this.selectedCurrencies.some((c: Currency) => this.compareCurrency(c, currency))
    },
    compareCurrency (currencyA: Currency, currencyB: Currency) {
      return currencyA.name === currencyB.name && currencyA.blockchain === currencyB.blockchain
    },
    getSelectedCount (blockchain: Blockchain) {
      return this.selectedCurrencies.reduce((count: number, c: Currency) => {
        if (c.blockchain === blockchain) {
          return count + 1
        }
        return count
      }, 0)
    },
    onAllInput (isAll: boolean) {
      this.selectedCurrencies = isAll ? [...CURRENCIES] : []
    },
    onAllStableInput (isAll: boolean) {
      if (isAll) {
        STABLECOINS.forEach(curr => {
          const index = this.selectedCurrencies.findIndex((c: Currency) => this.compareCurrency(c, curr))
          if (index === -1) {
            this.selectedCurrencies.push(curr)
          }
        })
      } else {
        STABLECOINS.forEach(curr => {
          const index = this.selectedCurrencies.findIndex((c: Currency) => this.compareCurrency(c, curr))
          if (index !== -1) {
            this.selectedCurrencies.splice(index, 1)
          }
        })
      }
    },
    oCurrencyInput (currency: Currency) {
      const index = this.selectedCurrencies.findIndex((c: Currency) => this.compareCurrency(c, currency))
      if (index === -1) {
        this.selectedCurrencies.push(currency)
      } else {
        this.selectedCurrencies.splice(index, 1)
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
    onSaveClick () {
      if (this.onSuccess) {
        this.onSuccess(this.selectedCurrencies.map((curr: Currency) => new PrefferedCurrency(curr)))
      }
      this.$emit('close')
    },
  },
})
