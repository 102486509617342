import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import skillableMixin from '@/mixins/skillableMixin'
import Skill from '@/models-ts/Skill'

const SKILLS_LIMIT = 30

export default Vue.extend<any, any, any, any>({
  name: 'lx-skills-selection-modal',
  mixins: [skillableMixin],
  props: {
    onSuccess: Function,
    category: Skill,
    skills: {
      type: Array as PropType<Array<Skill>>,
      default: []
    }
  },
  data () {
    return {
      processing: true,
      saving: false,
      imgSrc: null,
      selectedCategory: null,
      selectedSkills: [],
      expandedLists: [],
      SKILLS_LIMIT,
    }
  },
  computed: {
    ...mapGetters({
      categories: 'skills/getCategories',
      getChilds: 'skills/getChilds',
      getRoots: 'skills/getRoots',
    }),
    subSkills () {
      return this.getSubSkills(this.selectedCategory)
    },
    categoriesMap () {
      return this.categories.map((category: Skill) => {
        let subSkills = this.getSubSkills(category)
        const total = subSkills.length
        const expanded = this.expandedLists.includes(category.id)
        if (!expanded) {
          subSkills = subSkills.slice(0, 3)
        }
        return { category, subSkills, expanded, total }
      })
    },
    totalSelectedMap () {
      return this.categories.reduce((map: any, category: Skill) => {
        map[category.id] = this.getSelectedSkills(category)
        return map
      }, {})
    },
    limitExceeded () {
      const totals = Object.values(this.totalSelectedMap).flat()
      const skillsSet = new Set(totals)
      return skillsSet.size >= SKILLS_LIMIT
    },
  },
  watch: {
    skillsLoaded: {
      handler () {
        if (this.skillsLoaded) {
          this.selectedCategory = this.categories[0]
          this.selectedSkills = this.skills
            .map((s: Skill) => this.predefinedSkills.find((ps: Skill) => ps.id === s.id))
            .filter(Boolean)
        }
      },
      immediate: true
    }
  },
  methods: {
    getSubSkills (category: Skill) {
      if (category) {
        const skills = this.getChilds(category.id)
        return skills.length ? skills : [category]
      }
      return []
    },
    isSelected (skill: Skill) {
      return this.selectedSkills.some((s: Skill) => s.id === skill.id)
    },
    getSelectedSkills (category: Skill) {
      const subSkills: Array<Skill> = this.getSubSkills(category)
      return this.selectedSkills.filter((skill: Skill) => subSkills.find(s => s.id === skill.id))
    },
    onCollapseCategory (category: Skill) {
      const index = this.expandedLists.findIndex((i: number) => i === category.id)
      if (index !== -1) {
        this.expandedLists.splice(index, 1)
      }
    },
    onExpandCategory (category: Skill) {
      this.expandedLists.push(category.id)
    },
    onCategoryClick (skill: Skill) {
      this.selectedCategory = skill
    },
    onSkillInput (skill: Skill) {
      const index = this.selectedSkills.findIndex((s: Skill) => s.id === skill.id)
      if (index === -1) {
        if (!this.limitExceeded) {
          this.selectedSkills.push(skill)
          const catIndex = this.selectedSkills.findIndex((s: Skill) => s.id === this.selectedCategory.id)
          if (catIndex === -1) {
            this.selectedSkills.push(this.selectedCategory)
          }
        }
      } else {
        this.selectedSkills.splice(index, 1)
        const hasSelected = this.selectedSkills.some((s: Skill) => this.subSkills.find((ss: Skill) => ss.id === s.id))
        if (!hasSelected) {
          const catIndex = (this.selectedSkills as Array<Skill>).findIndex((s: Skill) => s.id === this.selectedCategory.id)
          if (catIndex !== -1) {
            this.selectedSkills.splice(catIndex, 1)
          }
        }
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
    onSaveClick () {
      if (this.onSuccess) {
        this.onSuccess(this.selectedSkills)
      }
      this.$emit('close')
    },
  },
})
