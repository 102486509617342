import { render, staticRenderFns } from "./GigSkillsSelection.htm?vue&type=template&id=2844c9c8&scoped=true&"
import script from "./GigSkillsSelection.ts?vue&type=script&lang=js&"
export * from "./GigSkillsSelection.ts?vue&type=script&lang=js&"
import style0 from "./GigSkillsSelection.scss?vue&type=style&index=0&id=2844c9c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2844c9c8",
  null
  
)

export default component.exports