import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import skillableMixin from '@/mixins/skillableMixin'
import Skill from '@/models-ts/Skill'

export default Vue.extend<any, any, any, any>({
  name: 'lx-gig-skills-selection-modal',
  mixins: [skillableMixin],
  props: {
    onSuccess: Function,
    skillsLimit: {
      type: Number,
      default: () => 30,
    },
    category: {
      type: Skill,
      required: true,
    },
    skills: {
      type: Array as PropType<Array<Skill>>,
      default: []
    }
  },
  data () {
    return {
      selectedSkills: [],
    }
  },
  computed: {
    ...mapGetters({
      getChilds: 'skills/getChilds',
    }),
    subSkills () {
      const skills = this.getChilds(this.category.id)
      return skills.length ? skills : [this.category]
    },
    limitExceeded () {
      return this.selectedSkills.length > this.skillsLimit
    }
  },
  watch: {
    skillsLoaded: {
      handler () {
        if (this.skillsLoaded) {
          this.selectedSkills = this.skills
            .map((s: Skill) => this.predefinedSkills.find((ps: Skill) => ps.id === s.id))
            .filter(Boolean)
          if (!this.selectedSkills.find((sk: Skill) => sk.id === this.category.id)) {
            this.selectedSkills.push(this.category)
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    isSelected (skill: Skill) {
      return this.selectedSkills.some((s: Skill) => s.id === skill.id)
    },
    onSkillInput (skill: Skill) {
      const index = this.selectedSkills.findIndex((s: Skill) => s.id === skill.id)
      if (index === -1) {
        if (!this.limitExceeded) {
          this.selectedSkills.push(skill)
        }
      } else {
        this.selectedSkills.splice(index, 1)
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
    onSaveClick () {
      if (this.onSuccess) {
        this.onSuccess(this.selectedSkills)
      }
      this.$emit('close')
    },
  },
})
